// import React from "react";
// import Box from "@mui/material/Box";
// import SwipeableDrawer from "@mui/material/SwipeableDrawer";
// import Button from "@mui/material/Button";
// import List from "@mui/material/List";
// import Divider from "@mui/material/Divider";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemText from "@mui/material/ListItemText";

// import MenuIcon from "@mui/icons-material/Menu";
// import OlineSignLogo2 from "../../assets/img/oline-sign-logo-2@2x.svg";

// // import { Linking } from "react-native";

// export default function SwipeableTemporaryDrawer(props) {
//   // const { menuLinkWa } = props;

//   const [state, setState] = React.useState({
//     right: false,
//   });

//   const toggleDrawer = (open) => (event) => {
//     if (
//       event &&
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }

//     setState({ ...state, right: open });
//   };

//   return (
//     <div>
//       <React.Fragment key={"right"}>
//         <Button onClick={toggleDrawer(true)}>
//           <MenuIcon
//             style={{
//               width: "32px",
//               height: "32px",
//               color: "rgba(0, 0, 0, 0.32)",
//             }}
//           />
//         </Button>

//         <SwipeableDrawer
//           anchor={"right"}
//           open={state["right"]}
//           onClose={toggleDrawer(false)}
//           onOpen={toggleDrawer(true)}
//         >
//           <Box
//             sx={{ width: 250 }}
//             role="presentation"
//             onClick={toggleDrawer(false)}
//             onKeyDown={toggleDrawer(false)}
//           >
//             <List>
//               {/* <ListItem disablePadding>
//                 <ListItemButton onClick={onTapTarif}>
//                   <ListItemText primary={"Тарифы"} />
//                 </ListItemButton>
//               </ListItem> */}
//               <img
//                 style={{
//                   width: "100%",
//                   "margin-top": " 30px",
//                   padding: "20px",
//                 }}
//                 src={OlineSignLogo2}
//                 alt="Oline-sign Logo"
//               />
//               <Divider />
//               <a
//                 href={`wa/#/signatureVerification`}
//                 target="_blank"
//                 rel="noreferrer"
//               >
//                 <ListItem disablePadding>
//                   <ListItemButton>
//                     <ListItemText primary={"Проверить подпись"} />
//                   </ListItemButton>
//                 </ListItem>
//               </a>
//               <a href={`wa/#/sign_up`} target="_blank" rel="noreferrer">
//                 <ListItem disablePadding>
//                   <ListItemButton>
//                     <ListItemText primary={"Зарегистрироваться"} />
//                   </ListItemButton>
//                 </ListItem>
//               </a>
//               <a href={`wa/#/sign_in`} target="_blank" rel="noreferrer">
//                 <ListItem disablePadding>
//                   <ListItemButton>
//                     <ListItemText primary={"Войти"} />
//                   </ListItemButton>
//                 </ListItem>
//               </a>
//             </List>
//             {/* <div className="column_mobile_menu">
//               <Button
//                 href={`wa/#/signatureVerification`}
//                 target="_blank"
//                 rel="noreferrer"
//               >
//                 <div className={`buttons button-header`}>
//                   <div className={"button-header"}>
//                     <div className="frame-270988836">
//                       <div className={`sbsanstext-regular-normal-white-19px`}>
//                         <div className={`button-2 button-header`}>
//                           Проверить подпись
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </Button>
//               <Button href={`wa/#/sign_up`} target="_blank" rel="noreferrer">
//                 <div className={`buttons button-header`}>
//                   <div className={"button-header"}>
//                     <div className="frame-270988836">
//                       <div className={`sbsanstext-regular-normal-white-19px`}>
//                         <div className={`button-2 button-header`}>
//                           Зарегистрироваться
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </Button>
//               <Button href={`wa/#/sign_in`} target="_blank" rel="noreferrer">
//                 <div className={`buttons button-header`}>
//                   <div className={"button-header"}>
//                     <div className="frame-270988836">
//                       <div className={`sbsanstext-regular-normal-white-19px`}>
//                         <div className={`button-2 button-header`}>Войти</div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </Button>
//             </div> */}
//           </Box>
//         </SwipeableDrawer>
//       </React.Fragment>
//     </div>
//   );
// }

import React from "react";
import List from "@mui/material/List";
// import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";

export default function TemporaryDrawer() {
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <br /> <br />
        <ListItem href={`wa/#/sign_in`} target="_blank" rel="noreferrer">
          <a
            href={`wa/#/signatureVerification`}
            target="_blank"
            rel="noreferrer"
          >
            <ListItemText primary="Проверить подпись" />
          </a>
        </ListItem>
        <ListItem>
          <a
            href={`wa/#/signatureVerification`}
            target="_blank"
            rel="noreferrer"
          >
            <ListItemText primary="Зарегистрироваться" />
          </a>
        </ListItem>
        <ListItem>
          <a
            href={`wa/#/signatureVerification`}
            target="_blank"
            rel="noreferrer"
          >
            <ListItemText primary="Войти" />
          </a>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <button onClick={toggleDrawer(true)}>
        <MenuIcon
          style={{
            width: "32px",
            height: "32px",
            color: "rgba(0, 0, 0, 0.32)",
          }}
        />
      </button>
      <Drawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
}
